<template>
    <Toast />
    <div style="padding: 16px 0px 0px 0px">
        <div class="card" style="padding: 32px 24px 32px 24px; ">
            <div class="flex mt-2">
                <div class="p-float-label">
                    <Dropdown style="height:100%" v-model="selectedField" :options="searchfields" optionLabel="name"
                        class="w-full md:w-14rem" />
                    <label>Opções de Busca</label>
                </div>
                <div v-if="selectedField != null && selectedField.id == 1" class="p-float-label ml-3">
                    <Chips style="height:100%" @keyup.enter="search" v-model="formIdList" separator=","
                        @add="handlePaste" :addOnBlur="true" />
                    <label for="formList"><i class="pi pi-search" style="font-weight: 900"></i>
                        ID do Formulário</label>
                </div>
                <div v-if="selectedField != null && selectedField.id == 2" class="ml-3">
                    <AutoComplete field="user" style="height: 100%; width: 100%" :multiple="true" v-model="bpmList"
                        :suggestions="filteredUsers" @complete="searchUsers($event, 'users')" :forceSelection="true"
                        :placeholder="bpmList.length == 0 ? 'Adicionar pessoa' : ''" />
                </div>
                <div v-if="selectedField != null && selectedField.id == 3" class="ml-3">
                    <AutoComplete field="user" style="height: 100%; width: 100%" :multiple="true" v-model="rexList"
                        :suggestions="filteredUsers" @complete="searchUsers($event, 'users')" :forceSelection="true"
                        :placeholder="rexList.length == 0 ? 'Adicionar pessoa' : ''" />
                </div>
                <div v-if="selectedField != null && selectedField.id > 1">
                    <Calendar class="ml-3" v-model="startYear" view="year" dateFormat="yy" style="height:100%"
                        placeholder="Selecione o ano inicial" />
                    <Calendar class="ml-3" v-model="endYear" view="year" dateFormat="yy" style="height:100%"
                        placeholder="Selecione o ano final" />
                </div>
                <div v-if="selectedField != null" class="text-left ml-3">
                    <button class="primaryButton" :disabled="loadingIconLocal" @click="search" title="Buscar"
                        severity="secondary">
                        <i :class="{
                            'pi pi-search': loadingIconLocal == false,
                            'pi pi-spin pi-spinner': loadingIconLocal == true,
                        }" style="font-weight: 900"></i>
                    </button>
                </div>
            </div>
            <div v-if="selectedField != null && selectedField.id == 4" class="mt-3">
                <AutoComplete field="label" style="height: 100%; width: 100%" :multiple="true" v-model="areaList"
                    :suggestions="filteredAreas" :forceSelection="true" @complete="searchAreas($event)"
                    :placeholder="areaList.length == 0 ? 'Adicionar área' : ''" />
            </div>
        </div>
        <div v-if="formListReturn != null">

            <div style="
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 16px;
            margin: 24px 0px 24px 0px;
            justify-content: end;
          ">
                <div class="body2" style="
              display: flex;
              gap: 8px;
              align-items: center;
              background-color: var(--color_gray_xlight);
              border-radius: 40px;
              padding: 0px 0px 0px 8px;
              font-weight: 700;
              color: var(--color_gray_dark);
            ">
                    <!-- Preencher REX -->
                    {{ getStatus('biaStatus1') }}
                    <Tag :value="counter.toFill" rounded style="
                background-color: var(--color_brand_light);
                min-width: 26px;
                color: var(--color_brand_xdark);
              "></Tag>
                </div>
                <div class="body2" style="
              display: flex;
              gap: 8px;
              align-items: center;
              background-color: var(--color_gray_xlight);
              border-radius: 40px;
              padding: 0px 0px 0px 8px;
              font-weight: 700;
              color: var(--color_gray_dark);
            ">
                    <!-- Aprovação BPM -->
                    {{ getStatus('biaStatus2') }}
                      
                    <Tag :value="counter.pendingBpmApproval" rounded style="
                background-color: var(--color_brand_medium);
                min-width: 26px;
              "></Tag>
                </div>
                <div class="body2" style="
              display: flex;
              gap: 8px;
              align-items: center;
              background-color: var(--color_gray_xlight);
              border-radius: 40px;
              padding: 0px 0px 0px 8px;
              font-weight: 700;
              color: var(--color_gray_dark);
            ">
                    <!-- Aprovação RO -->
                     <div v-if="checkTenant() == 'btg'">
                         {{ getStatus('biaStatus3') }}
                     </div>
                     <div v-else>
                         {{ getStatus('biaStatus3Pan') }}
                     </div>
                     
                    <Tag :value="counter.pendingRoApproval" rounded
                        style="background-color: var(--color_brand_dark); min-width: 26px"></Tag>
                </div>
                <div class="body2" style="
              display: flex;
              gap: 8px;
              align-items: center;
              background-color: var(--color_gray_xlight);
              border-radius: 40px;
              padding: 0px 0px 0px 8px;
              font-weight: 700;
              color: var(--color_gray_dark);
            ">
                    <!-- Finalizado -->
                    {{ getStatus('biaStatus4') }}
                    <Tag :value="counter.closed" rounded style="
                background-color: #479356;
                min-width: 26px;
              "></Tag>
                </div>
            </div>

            <!-- paginator :rows="rows" :rowsPerPageOptions="[5, 10, 20, 50]"
                :totalRecords="formListReturn.totalRecords" @page="paginate($event)"
                :first="(formListReturn.page - 1) * rows" lazy -->

            <DataTable class="customDataTableCampaign" v-model:expandedRows="expandedRows" :value="formListReturn">
                <Column expander style="width: 3rem; padding-right: 0"></Column>
                <Column style="padding-left: 0" field="header">
                    <template #body="slotProps">
                        <div style="
                        display: flex;
                        flex-direction: row;
                        padding: 0px 0px 0px 16px;
                        justify-content: space-between;
                        width: 100%;
                    ">
                            <div style="display: flex; flex-direction: column; gap: 8px">
                                <div class="body1" style="font-weight: 700">
                                    <!-- {{ slotProps }} -->
                                    {{ getAreaNameById(slotProps.data.area) }}
                                </div>
                                <div style="
                            display: flex;
                            flex-direction: row;
                            gap: 16px;
                            align-items: center;
                        ">
                                    <!-- <div class="body3" style="color: var(--color_gray_medium)">
                            <b>Última atualização:</b> 12/04/2023
                        </div>
                        <div class="body3" style="color: var(--color_gray_medium); font-size: 8px">
                            ●
                        </div>
                        <div class="body3" style="color: var(--color_gray_medium)">
                            Aqui vai um texto
                        </div> -->
                                </div>
                            </div>
                        </div>
                    </template>
                </Column>
                <template #expansion="slotProps">
                    <div style="
                    display: flex;
                    flex-direction: column;
                    justify-content: end;
                    align-items: center;
                    width: 100%;
                    ">
                        <BiaFormsResults v-for="(process, index) in slotProps.data.process" :id="process.id"
                            :key="index" :biaCode="process.name" :description="process.data.name"
                            :status="process.data.status" :bpmName="process.data.bpm" :rexName="process.data.rex"
                            :stepper="stepper" />
                    </div>
                </template>
            </DataTable>
        </div>
    </div>
</template>

<script>
import Util from "../service/Util"
import GraphService from "../service/GraphService"
import BiaService from "../service/BiaService";
// import IncidentService from "../service/IncidentService.js";
import FieldService from "../service/FieldService"
import BiaFormsResults from "../components/BiaFormsResults.vue"
import UserService from "../service/UserService";

export default {
    components: {
        BiaFormsResults
    },
    data() {
        return {
            rows: -1,
            expandedRows: [],
            selectedField: null,
            searchfields: [
                { name: "ID", id: 1 },
                { name: "BPM", id: 2 },
                { name: "REX", id: 3 },
                { name: "Área", id: 4 },
            ],
            loadingIconLocal: false,
            areaHierarchy: FieldService.getField('bia', 'associatedArea'),
            filteredUsers: null,
            formIdList: [],
            bpmList: [],
            rexList: [],
            areaList: [],
            formListReturn: null,
            counter: {
                toValidate: 0,
                validated: 0,
                toFill: 0,
                pendingBpmApproval: 0,
                pendingRoApproval: 0,
                closed: 0,
            },
            allAreasList: [],
            filteredAreas: null,
            query: null,
            startYear: null,
            endYear: null
        };
    },
    async created() {
        await this.populateHierarchyArray();
    },
    mounted() {

    },
    watch: {
        endYear() {
            if (this.startYear != null && this.endYear != null) {
                if (this.startYear.getFullYear() > this.endYear.getFullYear()) {
                    this.endYear = null

                    this.$toast.add({
                        severity: "error",
                        summary: "Período inválido",
                        detail: "Ano final deve ser menor ou igual ao ano inicial",
                        life: 5000,
                    });
                    return
                }
            }
        }
    },
    methods: {
        checkTenant() {
            return UserService.company
        },
        async populateHierarchyArray() {
            function visitNodes(tree, path = []) {
                if (!tree) {
                    return [];
                }

                path.push(tree.label);
                const info = {
                    id: tree.id,
                    label: tree.label,
                    path: path.join(" -> "),
                    selectable: tree.selectable,
                };

                const result = [info];

                if (tree.children && tree.children?.length > 0) {
                    for (const children of tree.children) {
                        result.push(...visitNodes(children, path.slice()));
                    }
                }

                return result;
            }

            for (let i = 0; i < this.areaHierarchy?.length; i++) {
                this.allAreasList = this.allAreasList.concat(
                    visitNodes(this.areaHierarchy[i])
                );
            }

            return true
        },
        searchUsers(event, filter = "users") {
            let tempArray = [];

            GraphService.searchUser(event.query, filter).then((response) => {
                response.forEach((user) => {
                    if (user.mail != "") {
                        tempArray.push({
                            user: Util.emailToName(user.mail.toLowerCase()),
                            mail: user.mail.toLowerCase()
                        });
                    }
                });
                this.filteredUsers = tempArray;
            });
        },
        handlePaste() {
            let tempArray = [];

            this.formList.forEach((id) => {
                if (id.includes("\r")) {
                    let brokenIds = id.split("\r");

                    brokenIds.forEach((brokenId) => {

                        let tempVar = brokenId.replace(/[^0-9]/g, "");

                        if (tempVar != "") {
                            tempArray.push(tempVar);
                        }
                    });
                } else {
                    tempArray.push(id.replace(/[^0-9]/g, ""));
                }
            });
            this.formIdList = tempArray;
        },
        search() {
            this.formListReturn = null
            this.loadingIconLocal = true



            if (this.selectedField?.id == 1) { // id
                if (this.formIdList.length == 0) {
                    this.$toast.add({
                        severity: "error",
                        summary: "ID em branco",
                        life: 5000,
                    });
                    this.loadingIconLocal = false
                    return
                }

                this.query = {
                    pageSize: this.rows,
                    page: 1,
                    sqlWhereClause: {
                        condition: "OR",
                        rules: []
                    }
                }

                this.formIdList.forEach((id) => {
                    this.query["sqlWhereClause"].rules.push({
                        "field": "id",
                        "type": "integer",
                        "operator": "equal",
                        "value": id
                    })
                })

                BiaService.listForms(this.query).then((resp) => {
                    this.loadingIconLocal = false
                    if (resp.success) {
                        let result = resp.data

                        if (result.length == 0) {
                            this.$toast.add({
                                severity: "error",
                                summary: "Formulário(s) não encontrado(s)",
                                life: 5000,
                            });
                            return
                        }

                        this.countStatus(result)
                        this.formListReturn = result
                        this.expandedRows = result

                        setTimeout(() => {
                            const element = document.querySelector('.p-datatable-thead');
                            if (element) {
                                element.remove();
                            }
                        }, 100);

                    }
                    else {
                        this.$toast.add({
                            severity: "error",
                            summary: "Erro ao buscar",
                            life: 5000,
                        });
                    }
                })
            }
            if (this.selectedField?.id == 2) { // bpm

                if (this.bpmList.length == 0 || (this.startYear == null && this.endYear == null)) {
                    this.$toast.add({
                        severity: "error",
                        summary: "Campos em branco",
                        life: 5000,
                    });
                    this.loadingIconLocal = false
                    return
                }

                this.query = {
                    pageSize: this.rows,
                    page: 1,
                    sqlWhereClause: {
                        condition: "OR",
                        rules: []
                    }
                }

                this.bpmList.forEach((bpm) => {
                    this.query["sqlWhereClause"].rules.push({
                        "field": "bpm",
                        "fieldPath": ["process"],
                        "type": "string",
                        "operator": "equal",
                        "value": bpm.mail
                    })
                })

                BiaService.listForms(this.query).then((resp) => {
                    this.loadingIconLocal = false
                    if (resp.success) {

                        let result = this.filterDates(resp.data)

                        if (result.length == 0) {
                            this.$toast.add({
                                severity: "error",
                                summary: "Formulário(s) não encontrado(s)",
                                life: 5000,
                            });
                            return
                        }

                        this.countStatus(result)
                        this.formListReturn = result
                        this.expandedRows = result

                        setTimeout(() => {
                            const element = document.querySelector('.p-datatable-thead');
                            if (element) {
                                element.remove();
                            }
                        }, 100);
                    }
                    else {
                        this.$toast.add({
                            severity: "error",
                            summary: "Erro ao buscar",
                            life: 5000,
                        });
                    }
                })
            }
            if (this.selectedField?.id == 3) { // rex
                if (this.rexList.length == 0 || (this.startYear == null && this.endYear == null)) {
                    this.$toast.add({
                        severity: "error",
                        summary: "Campos em branco",
                        life: 5000,
                    });
                    this.loadingIconLocal = false
                    return
                }

                this.query = {
                    pageSize: this.rows,
                    page: 1,
                    sqlWhereClause: {
                        condition: "OR",
                        rules: []
                    }
                }

                this.rexList.forEach((rex) => {
                    this.query["sqlWhereClause"].rules.push({
                        "field": "rex",
                        "fieldPath": ["process"],
                        "type": "string",
                        "operator": "equal",
                        "value": rex.mail
                    })
                })

                BiaService.listForms(this.query).then((resp) => {
                    this.loadingIconLocal = false
                    if (resp.success) {
                        let result = this.filterDates(resp.data)

                        if (result.length == 0) {
                            this.$toast.add({
                                severity: "error",
                                summary: "Formulário(s) não encontrado(s)",
                                life: 5000,
                            });
                            return
                        }

                        this.countStatus(result)
                        this.formListReturn = result
                        this.expandedRows = result

                        setTimeout(() => {
                            const element = document.querySelector('.p-datatable-thead');
                            if (element) {
                                element.remove();
                            }
                        }, 100);
                    }
                    else {
                        this.$toast.add({
                            severity: "error",
                            summary: "Erro ao buscar",
                            life: 5000,
                        });
                    }
                })
            }
            if (this.selectedField?.id == 4) { // area
                if (this.areaList.length == 0 || (this.startYear == null && this.endYear == null)) {
                    this.$toast.add({
                        severity: "error",
                        summary: "Campos em branco",
                        life: 5000,
                    });
                    this.loadingIconLocal = false
                    return
                }

                this.query = {
                    pageSize: this.rows,
                    page: 1,
                    sqlWhereClause: {
                        condition: "OR",
                        rules: []
                    }
                }

                this.areaList.forEach((area) => {
                    this.query["sqlWhereClause"].rules.push({
                        "field": "area",
                        "fieldPath": ["process"],
                        "type": "integer",
                        "operator": "equal",
                        "value": area.id.toString()
                    })
                })

                BiaService.listForms(this.query).then((resp) => {
                    this.loadingIconLocal = false
                    if (resp.success) {
                        let result = this.filterDates(resp.data)

                        if (result.length == 0) {
                            this.$toast.add({
                                severity: "error",
                                summary: "Formulário(s) não encontrado(s)",
                                life: 5000,
                            });
                            return
                        }

                        this.countStatus(result)
                        this.formListReturn = result
                        this.expandedRows = result

                        setTimeout(() => {
                            const element = document.querySelector('.p-datatable-thead');
                            if (element) {
                                element.remove();
                            }
                        }, 100);
                    }
                    else {
                        this.$toast.add({
                            severity: "error",
                            summary: "Erro ao buscar",
                            life: 5000,
                        });
                    }
                })
            }
        },
        countStatus(data) {
            this.counter = {
                toValidate: 0,
                validated: 0,
                toFill: 0,
                pendingBpmApproval: 0,
                pendingRoApproval: 0,
                closed: 0,
            }

            data.forEach((area) => {
                area.process.forEach((process) => {

                    switch (process.data.status) {
                        case "toValidate":
                            this.counter.toValidate++;
                            break;
                        case "validated":
                            this.counter.validated++;
                            break;
                        case "toFill":
                            this.counter.toFill++;
                            break;
                        case "reFill":
                            this.counter.toFill++;
                            break;
                        case "pendingBpmApproval":
                            this.counter.pendingBpmApproval++;
                            break;
                        case "pendingRoApproval":
                            this.counter.pendingRoApproval++;
                            break;
                        case "closed":
                            this.counter.closed++;
                            break;
                    }
                });
            });
        },
        getAreaNameById(id) {
            var areaName = null;

            areaName = this.allAreasList.filter((area) => area.id == id);

            return areaName[0]?.label;
        },
        paginate(event) {
            this.formListReturn = null

            this.rows = event.rows;
            this.query.page = event.page + 1;
            this.query.pageSize = event.rows;

            BiaService.listForms(this.query).then((resp) => {
                if (resp.success) {
                    this.countStatus(resp.data)
                    this.formListReturn = resp.data
                    this.expandedRows = resp.data

                    setTimeout(() => {
                        const element = document.querySelector('.p-datatable-thead');
                        if (element) {
                            element.remove();
                        }
                    }, 100);
                }
            })
        },
        getStatus(key) {
            return this.$langGetValue(key)
        },
        setAreas(areas) {
            this.areaList = areas
        },
        filterDates(area) {
            let tempArray

            if (this.startYear != null) {
                tempArray = area.map(item => ({
                    ...item,
                    process: item.process.filter(proc => {
                        const year = new Date(proc.data.estimatedDateOfConclusion).getFullYear();
                        return year >= this.startYear.getFullYear();
                    })
                })).filter(item => item.process.length > 0);
            }

            if (this.endYear != null) {
                tempArray = area.map(item => ({
                    ...item,
                    process: item.process.filter(proc => {
                        const year = new Date(proc.data.estimatedDateOfConclusion).getFullYear();
                        return year <= this.endYear.getFullYear();
                    })
                })).filter(item => item.process.length > 0);
            }

            return tempArray
        },
        searchAreas(event) {
            this.filteredAreas = this.allAreasList.filter((area) => {
                return area.label.toUpperCase().includes(event.query.toUpperCase());
            });
        }
    },
};
</script>

<style scoped>
.customDataTableCampaign th tr {
    display: none;
}

.customDataTableCampaign .p-datatable-tbody tr td {
    padding-top: 16px;
    padding-bottom: 16px;
}

.customDataTableCampaign .p-datatable-loading-overlay {
    display: none;
}

.customDataTableCampaign .p-datatable-row-expansion {
    background: white !important;
}
</style>